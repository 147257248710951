import './App.css';
import logo from './logo copy.png'

function App() {
  return (
    <div>
      <div className="container-fluid main-container">
      
      
      <div className="container main-content">
        
          
          <div className="heading">
          <h1>UTC Login</h1>
          </div>
          <div className='content'>
            <div class="mb-3">
              {/* <label for="exampleFormControlInput1" className="form-label">Employee ID</label> */}
              <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Employee ID" />
            </div>
            <div class="mb-3">
              {/* <label for="exampleFormControlInput1" className="form-label">Password</label> */}
              <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Enter Password" />
            </div>
            <div className='btn-grp'>
              <div class="d-grid gap-2">
                <button class="btn cstm-btn" type="button">Login</button>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div className="poweredBy">
      <div className="powered-para">
        Powered by
      </div>
    <img className='site-logo' src={logo} alt="logo" />
    </div>
    </div>
  );
}

export default App;
